<template>
	<div>
		<div class="m-alert-migrate" v-if="loaded">
			<div class="title-big">Attention</div>

			<div v-if="this.user.isCommercialAgent">

				<p>
					Vous venez de vous connecter avec votre ID ! Depuis le {{ this.formateDateMultico(this.multicoDate) }}, vous devez désormais utiliser votre email pour accéder à votre accès personnel. </p>

				<div v-if="daysFromMultico(this.multicoDate) < 60">
					<p>Nous vous rappelons que vous ne pourrez plus accéder à l’espace adhérent avec votre ID à partir du {{ formateDateEndMultico(this.multicoDate) }}.</p>
					<div class="bottom-center">
						<a class="button" @click="close">J’ai compris</a>
					</div>
				</div>
				<div v-else>
					<div class="bottom-center">
						<a  class="button" @click="logOut">Se déconnecter</a>
					</div>
				</div>
			</div>

			<div v-else-if="needMigration">
				<div v-if="daysFromMultico(this.multicoDate) < 60">
					<div v-if="this.collaborators.length > 0">
						<p>Vous venez de vous connecter à votre compte société ! Nous vous rappelons que votre représentant légal doit désormais activer la multi-connexion si vous souhaitez continuer à utiliser votre espace adhérent.</p>

						<form @submit.prevent="activate">
							<div class="bottom-center">
								<submit text="Informer mon représentant légal" :loading="loading" :success="success" :text-success="'Email envoyé à '+emails.join(', ')"></submit>
							</div>
						</form>
					</div>
					<div v-else>
						<p>Vous venez de vous connecter à votre compte société ! Depuis le {{ this.formateDateMultico(this.multicoDate) }}, chaque représentant légal doit activer son compte personnel pour permettre la multi-connexion sur son espace adhérent. En effet, cette évolution vous permettra ensuite d’inviter vos futurs collaborateurs à créer leur propre accès.</p>
						<p>Nous vous rappelons que sans l’activation de votre compte personnel, vous ne pourrez plus accéder à votre espace à partir du {{ formateDateEndMultico(this.multicoDate) }}.</p>
						<p>Merci de votre compréhension.</p>
						<form @submit.prevent="activate">
							<div class="bottom-center">
								<submit text="Activer mon compte personnel" :loading="loading" :success="success" :text-success="'Email envoyé à '+emails.join(', ')"></submit>
							</div>
						</form>
					</div>
				</div>

				<div v-else>
					<div v-if="this.collaborators.length > 0">
						<p>Votre compte société est bloqué ! Pour continuer à utiliser l’espace adhérent, le représentant légal doit activer la multi-connexion en créant son compte personnel. Il pourra ensuite inviter ses collaborateurs à créer leurs propres accès.</p>

						<form @submit.prevent="activate">
							<div class="bottom-center">
								<submit text="Informer mon représentant légal" :loading="loading" :success="success" :text-success="'Email envoyé à '+emails.join(', ')"></submit>
							</div>
						</form>
						<div class="bottom-center">
							<a class="link" @click="logOut">Se déconnecter</a>
						</div>
					</div>
					<div v-else>
						<p>
							Votre compte société est bloqué ! Vous devez désormais activer votre compte personnel pour permettre la multi-connexion sur votre espace adhérent. En effet, cette évolution vous permettra ensuite d’inviter vos futurs collaborateurs à créer leur propre accès.
						</p>

						<form @submit.prevent="activate">
							<div class="bottom-center">
								<submit text="Activer mon compte personnel" :loading="loading" :success="success" :text-success="'Email envoyé à '+emails.join(', ')"></submit>

							</div>
						</form>
						<div class="bottom-center">
							<a class="link" @click="logOut">Se déconnecter</a>
						</div>
					</div>
				</div>
			</div>
			<div v-else>

				<div v-if="daysFromMultico(this.multicoDate) < 60">

					<div v-if="this.collaborators.length > 0">
						<p>
							Vous venez de vous connecter à votre compte société !<br/>
							Depuis le {{ this.formateDateMultico(this.multicoDate) }}, chaque membre de votre société doit désormais utiliser son accès personnel. Si votre représentant légal ne vous a pas invité à l’activer, nous vous remercions de prendre contact avec lui.</p>

						<p>Nous vous rappelons que sans ce compte personnel, vous ne pourrez plus accéder à l’espace adhérent à partir du {{ formateDateEndMultico(this.multicoDate) }}.</p>
						<div class="bottom-center">
							<a class="button" @click="close">J'active mon compte</a>
						</div>
					</div>

					<div v-else>
						<p>Vous venez de vous connecter à votre compte société !<br/>
							Depuis le {{ this.formateDateMultico(this.multicoDate) }}, vous devez utiliser votre adresse email pour vous connecter. En effet, cette évolution vous permettra ensuite d’inviter vos futurs collaborateurs à créer leur propre accès.</p>
						<p>Nous vous rappelons que vous ne pourrez plus accéder à votre espace avec votre compte société à partir du
							{{ formateDateEndMultico(this.multicoDate) }}.</p>
						<p>Merci de votre compréhension.</p>

						<div class="bottom-center">
							<a class="button" @click="close">J’ai compris</a>
						</div>
					</div>
				</div>

				<div v-else>
					<div v-if="this.collaborators.length > 0">
						<p>
							Votre compte société est bloqué ! Le représentant légal doit désormais inviter chaque collaborateur à créer son compte personnel pour lui permettre d’accéder à l’espace adhérent. Si vous avez déjà créé un compte personnel, vous devez utiliser vos identifiants personnels pour vous connecter.
						</p>
						<div class="bottom-center">
							<a class="button" @click="logOut">Se déconnecter</a>
						</div>
					</div>

					<div v-else>
						<p>Vous venez de vous connecter à votre compte société ! Depuis le {{ this.formateDateMultico(this.multicoDate) }}, vous devez utiliser votre adresse email pour vous connecter.</p>
						<p>Merci de votre compréhension.</p>

						<div class="bottom-center">
							<a class="button" @click="logOut">Se déconnecter</a>
						</div>
					</div>

				</div>

			</div>

		</div>
	</div>

</template>

<script>

//TO DO

import contactRepository from '@/repositories/contactRepository';
import companyRepository from "@/repositories/companyRepository";
import userRepository from "@/repositories/userRepository";

export default {
	name: "migrate",
	props:['data'],
	data(){
		return{
			emails: [],
			loading: false,
			error: false,
			success: false,
			representatives: [],
			needMigration: true,
			loaded: false,
			collaborators: false
		}
	},
	methods:{
		logOut(){
			userRepository.logout();
		},
		close() {
			this.$emit('close');
		},
		activate() {

			this.loading = true;

			this.representatives.forEach(contact=>{

			  contactRepository.update(contact.id, {hasDashboard: true}).then(response => {

					this.loading = false;
					this.success = true;

					if( response.email )
					  this.emails.push(response.email);
				});
			})
		}
	},
	computed:{
		user(){
			return this.$user()
		},
    multicoDate(){
      return this.user.company.multico_activation_date;
    }
	},
	mounted(){
		if(!this.user.isCommercialAgent){
			companyRepository.getRepresentatives().then(contacts => {
				this.representatives = contacts;

				this.representatives.forEach(contact=>{
					if(contact.hasAccount)
						this.needMigration = false
				})
				companyRepository.getStaff().then(response=>{
					this.collaborators = response.items.filter(contact=>{ return contact.isValid && !contact.hasDashboard && !contact.isLegalRepresentative && !contact.isCommercialAgent })
					this.loaded = true;
				})

			});
		} else {
			this.needMigration = false
			this.loaded = true;
		}

	}
}
</script>

<style lang="scss">
@import '../../environment';
.m-migrate{
	width: 50rem;
	.title{ margin-bottom: $space }
	form{ margin-top: $space }

	.form-group + .form-group{ margin-top: $space-m }

	.button+.link{ margin-top: $space }

	p + p{ margin-top: $space-s }
}
</style>
