<template>
	<div class="v-order">
		<div v-if="loader" class="v-order__loader">
			<loader static="1"></loader>
			<span>Chargement de la commande...</span>
		</div>
		<div class="v-order__payment-error" v-if="paymentError">
			<p class="v-order__payment-error-notice">Le paiement a échoué, veuillez réessayer</p>
			<router-link class="button v-order__payment-error-cta" :to="back">Retour</router-link>
			</div>
		<div class="v-order__summary" v-else-if="order && !error">
			<router-link :to="back" class="v-order__back v-order__back--absolute icon-flex" data-icon="chevron-left">Retour</router-link>

			<div v-if="comesFromPsp || order.processed || status">
				<div class="title-big center" v-if="!processing">
					<a data-icon="check" class="v-order__icon"></a>
					<span>Commande effectuée avec succés</span>
				</div>
				<div class="title-big center" v-else>
					<loader static="1"></loader>
					<span>{{ processing }}</span>
				</div>
				<div class="title">
					<span v-if="order.payment">Récapitulatif de votre achat</span>
					<span v-else>Récapitulatif de votre commande</span>
				</div>
			</div>
			<div class="title-big" v-else>
				Récapitulatif de commande
			</div>

			<p class="primary v-order__details-title">
				<b>{{order.message}}</b>
			</p>
			<div class="v-order__details">
				<div v-if="order.type==='pack42h'">
					<order-detail-pack :data="order.details"></order-detail-pack>
				</div>
				<div v-else v-for="(detail,index) in order.details" :key="index" class="v-order__detail">
					<order-detail :data="detail"></order-detail>
				</div>
			</div>
			<div class="v-order__subtotal" v-if="order.totalAmount && hasTax">
				<div class="v-order__line">Sous total <span>{{formatPrice(order.totalAmount)}}</span></div>
				<div class="v-order__line">TVA ({{tax}}%) <span v-if="tax > 0">{{formatPrice(totalTax)}}</span><span v-else>{{tax}}€</span></div>
			</div>
			<div class="title-big v-order__total v-order__line" v-if="order.totalAmount">
				Total TTC <span>{{formatPrice(order.totalAmount+totalTax, true)}}</span>
			</div>
			<div class="title-big v-order__total v-order__line" v-else>
				Total TTC <span>Gratuit</span>
			</div>

			<div class="bottom-right relative">
				<div v-if="!comesFromPsp && !processed">
					<div v-if="order.totalAmount">
						<form @submit.prevent="getPaymentUrl">
							<submit text="Procéder au paiement" :loading="loading"></submit>
						</form>
						<router-link :to="{ name: 'formation', params:{ id: order.details[0].productId } }" class="v-order__back" :class="{'disabled':processing}" v-if="order.type==='formation'">Modifier ma commande</router-link>
					</div>
					<div v-else>
						<form @submit.prevent="executeOrder">
							<submit text="Procéder à la commande" :loading="processing" :success="processed" text-success="Commande exécuté"></submit>
						</form>
						<router-link :to="{ name: 'formation', params:{ id: order.details[0].productId } }" class="v-order__back" :class="{'disabled':processing}" v-if="order.type==='formation'">Modifier ma commande</router-link>
					</div>
				</div>
				<div v-if="order.processed" :class="{'disabled':processing}">
					<router-link class="button" to="/formations/formations-souscrites" v-if="order.type==='formation' || order.type==='pack42h'">Voir mes formations</router-link>
					<router-link class="button" to="/e-signatures" v-if="order.type==='signature'">Voir mes signatures</router-link>
					<router-link class="button" to="/" v-if="order.type.search('membership_')===0">Retour à l'accueil</router-link>
				</div>
			</div>
		</div>
		<div v-else-if="error">
			<b class="primary">Une erreur est survenue :</b> {{error}}
			<div class="bottom-right">
				<router-link :to="back" class="v-order__back">Retour</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import orderRepository from "@/repositories/orderRepository";
	import appendixRepository from "@/repositories/appendixRepository";
	import userRepository from "@/repositories/userRepository";

	export default {
		name: "Buy",
		data(){
			return{
				order: false,
				back: '/',
				error: false,
				payment_url: false,
				loading: false,
				loader: false,
				processing: false,
				processed: false,
				executeRetryCount: 0,
				paymentError: false
			}
		},
		methods:{
			getPaymentUrl(){

				this.loading = true;

				orderRepository.getPaymentUrl(this.order.id).then(url => {

					localStorage.setItem('from', this.$route.path);
					document.location.href = url;

				}, response=>{

					this.loading = false;
				});
			},
			getOrderStatus(paymentStatus, documentStatus){
				if(paymentStatus === 'cancelled' || paymentStatus === 'refunded' || paymentStatus === 'failed')
					return 'error'
				else if(paymentStatus === 'processing' || documentStatus === 'processing')
					return 'processing'
				else if (paymentStatus === 'completed' && documentStatus === 'completed')
					return 'done'
			},
			pollOrderStatus() {

				this.processing = "Commande en cours de traitement, merci de patienter...";

				this.statusPollingInterval = setInterval(() => {
					orderRepository.getStatus(this.$route.params.id).then(({ status }) => {
						this.status = this.getOrderStatus(status.payment, status.document)
						if (this.status === "done") {
							clearInterval(this.statusPollingInterval)
							if (this.order.type.search('membership_') === 0)
								userRepository.getMembership();
							appendixRepository.sync().then(response => {
								this.processed = true;
								this.order.processed = true;
								this.processing = false;
							}, response => {
								this.processing = false;
							});
						}
						else if (this.status === "error") {
							this.processing = false
							this.paymentError = true
							this.status = status
							clearInterval(this.statusPollingInterval)
						}
					})
				}, 5000)
				setTimeout(() => {
					if (this.processing) {
						clearInterval(this.statusPollingInterval)
						this.processing = false;
						this.error = "veuillez contacter le service client";
					}
				}, 60000)
			},
		},
		computed:{
			user(){
				return this.$user()
			},
			totalTax(){
				return this.hasTax ? this.order.details[0].taxRate * this.order.totalAmount : 0;
			},
			tax(){
				return this.order.details[0].taxRate*100;
			},
			hasTax(){
        return !["membership_vhs", "membership_asseris", "membership_caci"].includes(this.order.type);
			},
			comesFromPsp(){
				return this.$route.query.frompsp
			}
		},
		mounted() {
			this.loader = true;

				orderRepository.get(this.$route.params.id).then(order => {
					this.order = order;
					this.order.details[0].taxRate = this.$user().tva;
					this.processed = order.processed;

					if (!order.processed && this.comesFromPsp) {
						this.pollOrderStatus();
					}
				}).catch(err => {
					this.error = "Une erreur est survenue lors de la récupération de la commande.";
				}).finally(() => {
					this.loader = false;
				});
		},
		unmounted(){
			clearInterval(this.statusPollingInterval)
		}
	}
</script>

<style lang="scss">
	@import '../environment';
	.v-order{
		background: white; min-height: calc(100vh - 19.6rem); display: flex; justify-content: center; align-items: center; padding: $space-l 0;
    .app-vhs &{ background: none }
		&__logo{
			height: 4.5rem;
			img{ width: auto; height: 100% }
			& + *{ margin-top: $space-m }
		}

		&__summary{
			text-align: left;  width: 90%; max-width: 65rem;

			@media #{$from-tablet}{ width: 75% }
			@media #{$from-small}{ width: 50% }
		}

		&__icon{
			font-size: 4rem; color: $c-green; margin-bottom: $space-m; display: block;

			&:before{ padding:0.5rem; border-radius: 50%; border: solid 2px $c-green }
		}

		.title-big+.title{ margin-top: $space-ll }

		&__details-title{
			margin-top: $space-m; text-transform: capitalize;
			& + *{ margin-top: $space-m }
		}

		&__details{
			background: $c-background; padding: $space-m; position: relative; border-radius: 3px;
      .app-vhs &{ background: #fff }
    }

		&__detail{
			&+&{ margin-top: 1rem }
		}

		&__subtotal, &__total{ padding: $space-m $space-m 0 $space-m; }
		&__total{
			margin-top: $space-m;
		}

		&__line{ display: flex; justify-content: space-between; padding: 0.5rem 0 }

		&__back{
			display: inline-block; margin-top:1rem; cursor: pointer; color: $c-primary; font-size: $font-s;

			&--absolute{ position: absolute; top: $space-m; left: $space-m; margin-top: 0 }
		}
		&__loader {
			justify-content: center;
			align-items: center;
			span {
				margin-top: 1rem;
			}
		}
		&__payment-error{
			display: flex; flex-direction: column; gap: $space-s; align-items: center;
			&-notice{
				margin-top: $space-l;
				font-size: 2rem;
				font-weight: bold;
				color: red;
			}
		}
	}
</style>