import Vue from "vue";
import store from "@/services/store";

export default {

	create(params) {

		return new Promise((resolve, reject) => {

			if( params.orderId ){

				Vue.http.post('order/'+params.orderId, params).then(response=>{

					resolve(params.orderId);

				}, reject);
			}
			else{

				Vue.http.post('order', params).then(response=>{

					let orderId = response.body.response.id;

					params.orderId = orderId;

					resolve(orderId);

				}, reject);
			}
		});
	},

	getCart(params) {

		return store.getters.cart(params);
	},

	getPaymentUrl(id, returnUrl) {

		if( typeof returnUrl == 'undefined' )
			returnUrl = '/order/'+ id;

		return new Promise((resolve, reject) => {

			Vue.http.post('payment', {orderId:id, returnUrl: window.location.origin+returnUrl}).then(response => {

				resolve(response.body.response);

			}, reject)
		})
	},

	get(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('order/'+id).then(response => {

				resolve(response.body.response);

			}, reject);
		})
	},

	execute(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('order/'+id+'/execute').then(response => {

				resolve(response.body.response);

			}, reject);
		})
	},

	getStatus(orderId) {
		return new Promise((resolve, reject) => {
			Vue.http.get(`v2/order/${orderId}/status`).then(response => {
				resolve(response.body.response);
			}).catch(reject);
		}) 
	  },
};
