<template>
    <div class="m-training-item__col" v-if="data.format === 'e-learning'">
      <div class="m-training-item__date">
        <img src="/img/icons/training-no-calendar.svg"/>
      </div>
      <div class="m-training-item__col--2">
        <img src="/img/icons/training-elearning.svg"/>
        <b>Formation en ligne</b>
      </div>
    </div>
    <div class="m-training-item__col" v-else-if="data.format === 'webinar'">
      <div class="m-training-item__date m-training-item__date&#45;&#45;small">
        <img src="/img/icons/training-instructor.svg"/>
        <time class="m-training-item__date-short" v-html="formatShortDate(data.startAt)"></time>
      </div>
      <div class="m-training-item__col--2">
        <img src="/img/icons/training-webinar.svg"/>
        <b>Formation webinaire</b>
      </div>
    </div>
    <div class="m-training-item__col" v-else>
      <div class="m-training-item__date m-training-item__date&#45;&#45;small">
        <img src="/img/icons/training-instructor.svg"/>
        <time class="m-training-item__date-short" v-html="formatShortDate(data.startAt)"></time>
      </div>
      <div class="m-training-item__col--2">
        <img src="/img/icons/training-face-to-face.svg"/>
        <b>Formation en présentiel</b>
      </div>
    </div>
</template>
<script>
export default {
    props: ["data"]
}
</script>