<template>
  <div class="v-renewal_page">
    <header-component />
    <loader v-if="loading" static="1"></loader>
    <div v-else-if="status === 'expired'" class="v-renewal__main">
      <h1 class="v-renewal__main-notice">Aucun paiement à effectuer</h1>
    </div>
    <div v-else-if="status === 'error'" class="v-renewal__error">
      {{error ? error : "Une erreur est survenue. Veuillez contacter le service client"}}
    </div>
    <div v-else class="v-renewal">
      <div class="v-renewal__rightbar">
        <div class="v-renewal__steps" v-if="steps">
          <div
            class="v-renewal__step"
            :class="{
              'v-renewal__step--active':
                step.status === 'done' || step.status === 'current',
            }"
            v-for="(step, index) in steps"
            v-key="step.name"
          >
            <span
              v-if="step.status === 'done'"
              class="v-renewal__step-check"
              data-icon="check-circle"
            ></span>
            <span v-else class="v-renewal__step-index">{{ index + 1 }}</span>
            <span class="v-renewal__step-label">{{ step.name }}</span>
          </div>
          <div
            class="v-renewal__step"
            :class="{
              'v-renewal__step--active': status === 'terminate',
            }"
          >
            <span
              v-if="status === 'terminate'"
              class="v-renewal__step-check"
              data-icon="check-circle"
            ></span>
            <span v-else class="v-renewal__step-index">{{
              steps.length + 1
            }}</span>
            <span class="v-renewal__step-label">Mes documents</span>
          </div>
        </div>
      </div>
      <div v-if="currentStep || status == 'terminate'" class="v-renewal__main">
        <template v-if="status === 'start'">
          <h1 class="v-renewal__main-title">
            Votre {{ currentIsPrime ? "prime" : "cotisation" }} pour l'année 2025
          </h1>
          <img class="v-renewal__main-image" :src="currentImageUrl" />
          <span class="v-renewal__main-amount"
            >Montant de la {{ currentIsPrime ? "prime" : "cotisation" }} : <br />{{
              formatPrice(currentStep.total_amount)
            }}</span
          >
          <div v-if="details.length" class="v-renewal__main-details">
            <span v-if="primeExMention" class="v-renewal__main-details-title">{{ primeExMention }}</span>
            <span>dont</span>
            <span v-for="detail in details">{{ detail.category }} : {{ formatPrice(detail.amount)}}</span>
          </div>
          <div v-else-if="user" class="v-renewal__user">
            <div class="v-renewal__user-avatar">
              <img v-if="user.avatar" :src="user.avatar" />
            </div>
            <div class="v-renewal__user-infos">
              <span class="v-renewal__user-company">{{ user.name }}</span
              >
              <span>N° d'adhérent : <br />{{ user.memberId }}</span>
            </div>
          </div>
          <button
            class="button"
            :class="{ 'button--disabled': pending }"
            :disabled="pending"
            @click="createOrder"
          >
            Régler ma {{ currentIsPrime ? "prime" : "cotisation" }}
            <loader v-if="pending"></loader>
          </button>
        </template>
        <template v-else-if="status === 'order'">
          <div class="v-order__summary">
            <div class="title-big">Récapitulatif de commande</div>

            <p class="primary v-order__details-title">
              <b>{{ order.message }}</b>
            </p>
            <div class="v-order__details">
              <div
                v-for="(detail, index) in order.details"
                :key="index"
                class="v-order__detail"
              >
                <order-detail :data="detail"></order-detail>
              </div>
            </div>
            <div class="v-order__subtotal" v-if="order.totalAmount && hasTax">
              <div class="v-order__line">
                Sous total <span>{{ formatPrice(order.totalAmount) }}</span>
              </div>
              <div class="v-order__line">
                TVA ({{ tax }}%)
                <span v-if="tax > 0">{{ formatPrice(totalTax) }}</span
                ><span v-else>{{ tax }}€</span>
              </div>
            </div>
            <div
              class="title-big v-order__total v-order__line"
              v-if="order.totalAmount"
            >
              Total TTC
              <span>{{ formatPrice(order.totalAmount + totalTax, true) }}</span>
            </div>
            <div class="title-big v-order__total v-order__line" v-else>
              Total TTC <span>Gratuit</span>
            </div>
            <button class="button" @click="goToPayement">
              Paiement {{ currentIsPrime ? "prime" : "cotisation" }}
            </button>
          </div>
        </template>
        <template v-else-if="status === 'polling'">
          <h1 class="v-renewal__main-title">
            Validation de la {{ currentStep.name }}
          </h1>
          <div class="v-renewal__statuses">
            <div
              v-if="orderStatuses.length > 0"
              class="v-renewal__statuses--separator"
            ></div>
            <div
              class="v-renewal__status"
              v-for="orderStatus in orderStatuses"
              :key="orderStatus.label"
            >
              <div class="v-renewal__status-mask"></div>
              <div class="v-renewal__status-background">
                <span
                  v-if="orderStatus.status"
                  class="v-renewal__status-check"
                  data-icon="check-circle"
                ></span>
                <div v-else class="v-renewal__status-spinner"></div>
              </div>
              <span
                class="v-renewal__status-label"
                :class="{
                  'v-renewal__status-label--active': orderStatus.status,
                }"
                >{{ orderStatus.label }}</span
              >
            </div>
          </div>
          <button v-if="orderGlobalStatus === 'completed'" class="button v-renewal__statuses-cta" @click="goToNextStep">Continuer</button>
        </template>
        <template v-else-if="status === 'terminate'">
          <h1 class="v-renewal__main-title">Télécharger mes documents</h1>
          <div class="v-renewal__document-notice">
            <template v-if="isMultico">
              <h2>
                Nous vous invitons à vous connecter à votre espace adhérent pour
                récupérer votre facture
              </h2>
              <a class="button" :href="autologinUrl" @click="clearLocalStorage">
                Accéder à mon espace
              </a>
            </template>
            <template v-else>
              <h2>Pour récupérer votre facture, activez votre multico !</h2>
              <p>
                Faites un pas vers la simplicité : chaque collaborateur dispose
                désormais d’un accès personnel et sécurisé à l’espace SNPI, au
                lieu d’un compte unique par société. Créez simplement un mot de
                passe, et laissez-nous nous occuper du reste !
              </p>
              <a class="button" :href="autologinUrl" @click="clearLocalStorage">Accéder à mon espace</a>
            </template>
          </div>
        </template>
      <div class="v-renewal__error" v-if="status=='payment_error'">
        <p class="v-renewal__error-notice">Le paiement a échoué, veuillez réessayer</p>
        <a class="button v-renewal__error-cta" :href="`/renewal?step=${currentStep.type}`">Retour</a>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderRepository from "../repositories/orderRepository";
import renewalRepository from "../repositories/renewalRepository";

export default {
  name: "renewal",
  data() {
    return {
      token: false,
      status: "start",
      user: false,
      order: false,
      pending: false,
      currentStepId: false,
      orderStatuses: false,
      orderGlobalStatus: false,
      membership: false,
      isMultico: false,
      autologinToken: false,
      loading: false,
      pollingInterval: false,
      pollingTimeout: false,
      error: false,
      terminated: false
    };
  },
  mounted() {
    const isTokenNew = this.$route.query.token && this.$route.query.token !== localStorage.getItem("renewal_token");
    this.token = isTokenNew
      ? this.$route.query.token
      : localStorage.getItem("renewal_token");
    this.loading = true

    if(!(localStorage.getItem("renewal_token") || this.$route.query.token)){
      this.status = "error"
      this.error = "Aucune connexion active, veuillez cliquer sur le lien transmis dans l'email d'invitation des renouvellements."
      this.loading = false;
      return;
    }
    Promise.all([
      renewalRepository.getMembership(this.token),
      renewalRepository.getUser(this.token),
    ]).then(([{body: {response: membershipResponse}}, {body: {response: userResponse}}]) => {
      this.updateStoredSteps(membershipResponse)
      if (isTokenNew) {
        if(this.membershipHasPositiveAmounts(membershipResponse)){
          this.clearLocalStorage();
          localStorage.setItem("renewal_token", this.token);
          this.updateStoredSteps(membershipResponse)
          const stepKeys = localStorage.getItem("steps");
          this.currentStepId = stepKeys[0];
        }
        else{
          this.terminate();
          return;
        }
      }
      else {
        this.updateStoredSteps(membershipResponse)
      }
      this.membership = membershipResponse;

      if (this.$route.query.step) {
        this.currentStepId = this.$route.query.step;
        this.loading = false;
      } else if (this.$route.query.terminate || !this.membershipHasPositiveAmounts(membershipResponse)) {
        this.terminate();
      }
      else{
        this.currentStepId = Object.entries(membershipResponse)
          .filter(([_, value]) => value.total_amount > 0)[0][0]
        this.loading = false;
      }

      this.user = userResponse;

      if (this.$route.query.order) {
        if(this.$route.query.payment_state==="success"){
          this.status = "polling";
          this.pollOrderStatuses();
          this.pollingInterval = setInterval(this.pollOrderStatuses, 5000);
          this.pollingTimeout = setTimeout(this.abortPoll, 60000);
        }
        else{
          this.status = "payment_error"
        }
      }
    }).catch((error)=>{
      console.error(error)
      if(error.body && error.body.status_code === 400){
        this.status="expired"
      }
      else{
        this.status="error"; 
      }
      this.loading = false;
    })
  },
  unmounted(){
    clearInterval(this.pollingInterval)
    clearTimeout(this.pollingTimeout)
  },
  computed: {
    steps() {
      if (localStorage.getItem("steps") !== undefined && this.membership) {
        const stepKeys = localStorage.getItem("steps");
        let primeCount = 0
        return Object.entries(this.membership)
          .filter(([key, _]) => stepKeys.includes(key))
          .map(([key, value], index) => {
            let status = "todo";
            if (index < this.currentStepIndex || this.status === "terminate")
              status = "done";
            else if (index === this.currentStepIndex) status = "current";
            let name = ""
            if(key==="snpi")
              name = "cotisation SNPI"
            else{
              name = primeCount > 0 ? `prime assurances ${primeCount+1}` : 'prime assurances';
              primeCount++;
            }
            return {
              ...value,
              type: key,
              status,
              name,
            };
          });
      }
    },
    currentStep() {
      return this.steps
        ? this.steps.find((step) => step.type === this.currentStepId)
        : false;
    },
    currentStepIndex() {
      const stepKeys = localStorage.getItem("steps").split(",");
      return stepKeys.findIndex((stepKey) => stepKey === this.currentStepId);
    },
    currentImageUrl() {
      const images = {
        snpi: "/img/logo-snpi-baseline.svg",
        vhs: "/img/logo_snpi_assurances.png",
        asseris: "/img/logo_snpi_assurances.png",
        caci: "/img/logo-caci.svg",
      };
      const image = images[this.currentStep.type];
      return image ? image : images["snpi"];
    },
    currentIsPrime(){
      return this.currentStep.name.includes("prime")
    },
    totalTax() {
      return this.hasTax
        ? this.order.details[0].taxRate * this.order.totalAmount
        : 0;
    },
    tax() {
      return this.user.tax_rate * 100;
    },
    hasTax() {
      return !["membership_vhs", "membership_asseris"].includes(this.order.type);
    },
    autologinUrl() {
      return `/connection/${this.autologinToken}`;
    },
    details(){
      const details = this.currentStep.details ? this.currentStep.details.filter((details)=>details.category) : []
      return details
    },
    primeExMention(){
      switch (this.currentStep.type) {
        case 'vhs':
            return "Ex-VHS Assurances"
          break;
        case 'asseris':
          return 'Ex-Asseris'
        break;
        default:
          return null;
        break;
      }
    }
  },
  methods: {
    updateStoredSteps(membershipResponse){
      let storedKeys = localStorage.getItem("steps") ? localStorage.getItem("steps"): []
      const stepKeys = Object.entries(membershipResponse)
          .filter(([key, value]) => value.total_amount > 0 || key === "snpi" || storedKeys.includes(key))
          .map(([key, _]) => key);
        localStorage.setItem("steps", stepKeys);
    },
    createOrder() {
      this.pending = true;
      renewalRepository
        .createOrder(this.token, { type: "membership_" + this.currentStep.type })
        .then(({body: { response: {order, payment_url} }}) => {
          this.order = {...order, paymentUrl: payment_url};
          this.status = "order";
          this.pending = false;
        })
        .catch(()=>this.status="error")
    },
    goToPayement() {
      window.location = this.order.paymentUrl;
    },
    pollOrderStatuses() {
      const translation = {
        payment: ["Paiement en cours", "Paiement validé"],
        invoice: ["Génération de la facture", "Facture générée"],
        document: ["Génération de l'attestation", "Attestation générée"],
      };
      orderRepository.getStatus(this.$route.query.order).then((response) => {
        if(['failed', 'cancelled'].includes(response.status.payment)){
          this.status = "payment_error"
        }
        else{
          this.orderStatuses = Object.entries(response.status)
            .filter(([key, _]) => Object.keys(translation).includes(key) && (key!=="document" || this.currentIsPrime))
            .map(([key, value]) => {
              const status = value === "completed";
              return {
                type: key,
                label: translation[key][+status],
                status: status,
              };
            }).sort((a, b)=>{
              return Object.keys(translation).findIndex((key)=>key===a.type) > Object.keys(translation).findIndex((key)=>key===b.type) ? 1 : -1
            });
          this.orderGlobalStatus = response.status.global;
          if(!['pending', 'processing'].includes(this.orderGlobalStatus)){
            clearInterval(this.pollingInterval)
            clearTimeout(this.pollingTimeout)
          }
        }
      })
      .catch(()=>this.status="error")
    },
    abortPoll(){
      clearInterval(this.pollingInterval)
      clearTimeout(this.pollingTimeout)
      this.status = "error"
    },
    goToNextStep() {
      //handle last step
      if (this.currentStepIndex < this.steps.length - 1) {
        this.currentStepId = this.steps[this.currentStepIndex + 1].type;
        this.$router.replace({query: {}});
        this.status = "start";
      } else {
        this.status = "terminate";
        this.$router.replace({
          query: { terminate: true },
        });
      }
    },
    clearLocalStorage(){
      localStorage.removeItem("steps")
      localStorage.removeItem("renewal_token")
    },
    terminate(){
      if(this.terminated) return;
      this.status = "terminate";
      renewalRepository
        .terminate(this.token)
        .then(({ body: { response: { isMultico, token } } }) => {
          this.isMultico = isMultico;
          this.autologinToken = token;
          this.loading = false;
          this.terminated = true;
        })
        .catch((error) => {
          this.status = "error"
          this.loading = false
        })
    },
    membershipHasPositiveAmounts(membership){
      return Object.values(membership).filter((step) => step.total_amount > 0).length > 0
    }
  },
};
</script>

<style lang="scss">
@use "sass:math";
@import "../environment.scss";

.v-renewal {
  display: flex;
  flex-direction: row;
  height: 100svh;
  @media #{$to-tablet} {
    flex-direction: column;
    height: fit-content;
    width: 100%;
  }
  &__rightbar {
    flex-basis: 25%;
    background-color: $c-background;
  }
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 80%;
    background-color: white;
    padding: $space-l;

    &-title {
      font-size: 32px;
      color: $c-primary;
      font-weight: bold;
      margin-bottom: $space-l;
      @media #{$to-tablet} {
        font-size: 24px;
      }
    }
    &-image {
      width: 100%;
      max-width: 16rem;
      margin-bottom: $space-m;
      @media #{$to-phone} {
        width: 12rem;
      }
    }
    &-amount, &-notice {
      font-size: $font-xxl;
      font-weight: bold;
      color: $c-primary;
      line-height: $space-l;
    }
    &-details{
      display: flex;
      flex-direction: column;
      background-color: #dfe5ef;
      padding: 12px 17px;
      border-radius: $border-radius;
      color: $c-primary;
      margin-block: $space-m;
      min-width: 24rem;
      &-title{
        font-weight: bold;
      }
    }
  }
  &__user {
    width: fit-content;
    padding: 12px 17px;
    background-color: #dfe5ef;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: $border-radius;
    margin-block: $space-m;

    @media #{$to-phone} {
      flex-direction: column;
    }
    &-avatar {
      img {
        width: 5rem;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        object-fit: cover;
        margin-right: $space-m;
      }
    }
    &-infos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      span {
        text-align: left;
        color: $c-primary;
      }
    }
    &-company {
      font-weight: bold;
      margin-bottom: $space-s;
    }
  }
  &__steps {
    margin: $space-ll;
  }
  &__step {
    $step: &;
    margin-bottom: $space;
    width: fit-content;
    &-index,
    &-check {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      margin-right: $space-s;
    }
    &-index {
      border: 2px solid #d9d9d9;
      border-radius: 100%;
      font-weight: bold;
    }
    &-label {
      text-transform: uppercase;
      font-weight: bold;
    }
    &--active {
      #{$step}-label {
        color: $c-primary;
      }
      #{$step}-index {
        color: $c-primary;
        border-color: $c-primary;
      }
    }
    &-check {
      color: $c-green;
      font-size: 2rem;
    }
  }

  .v-order__summary {
    width: 70%;
    max-width: none;

    .title-big {
      text-align: center;
    }

    .button {
      display: block;
      margin-left: auto;
      margin-top: $space-s;
    }
  }
  &__statuses {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20rem;
    position: relative;
    @media #{$to-small} {
      gap: $space-l;
    }
    @media #{$to-tablet} {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }
    &--separator {
      position: absolute;
      top: 25%;
      left: 4rem;
      width: calc(100% - 15rem);
      height: 1px;
      background-color: $c-primary;
      @media #{$to-tablet} {
        display: none;
      }
    }
    &-cta{
      margin-top: $space;
    }
  }
  &__status {
    $status: &;
    $margin: 0.3rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media #{$to-tablet} {
      flex-direction: row;
      justify-content: space-between;
    }
    &-label {
      margin-top: $space;
      font-size: 2rem;
      font-weight: bold;
      white-space: nowrap;
      &--active {
        color: $c-primary;
      }
      @media #{$to-tablet} {
        margin-left: $space;
        margin-top: 0;
      }
    }
    &-background {
      &:has(#{$status}-spinner) {
        background-color: $c-background;
      }
      border-radius: 100%;
      width: $space + 3rem;
      height: $space + 3rem;
      position: relative;
    }
    &-mask {
      position: absolute;
      width: $space + 6rem;
      height: $space + 6rem;
      background-color: white;
    }
    &-spinner {
      position: absolute;
      left: 1 + math.div(1rem, 3);
      top: 1 + math.div(1rem, 3);
      display: block;
      width: $space - $margin;
      height: $space - $margin;
      margin: $margin;
      border: $margin solid $c-primary;
      border-radius: 50%;
      animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $c-primary transparent transparent transparent;
    }
    &-check {
      position: absolute;
      display: block;
      font-size: $space - $margin;
      color: $c-green;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__document-notice {
    background-color: $c-background;
    padding: $space-l;
    display: flex;
    flex-direction: column;
    gap: $space-l;
    border-radius: $border-radius;
    width: 70%;
    h2 {
      font-size: 2.2rem;
      color: $c-primary;
      font-weight: bold;
    }
    p {
      color: $c-primary;
      font-size: 2rem;
    }
    .button {
      width: fit-content;
      margin-inline: auto;
    }
  }
  &__error{
    display: flex; flex-direction: column; align-items: center; gap: $space;
    &-notice{
      color: red;
      font-size: $font-xxl;
      font-weight: bold;
    }
    &-cta{
      width: fit-content;
    }
  }
  &__page{
    .a-burger{
      display: none;
    }
  }
  &__error{
    margin-top: $space-l;
    font-size: 2rem;
    font-weight: bold;
    color: red;
  }
}
</style>
