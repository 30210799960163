<template>
    <div class="finite-progress-bar">
        <div class="finite-progress-bar__content" :style="`width: ${width}%`"></div>
    </div>
</template>
<script>
export default{
    props: ["progress"],
    computed: {
        width(){
            return this.progress * 100
        }
    }
}
</script>
<style lang="scss">
@import "../../environment.scss";

@keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}
.finite-progress-bar{
    background-color: $c-background;
    height: 2rem;
    &__content{
        height: 100%;
        background-image: linear-gradient(110deg, #{$c-primary-light} 25%, #{$c-primary} 25%, #{$c-primary} 50%, #{$c-primary-light} 50%, #{$c-primary-light} 75%, #{$c-primary} 75%, #{$c-primary});
        background-size: 20px 40px;
        animation: progress-bar-stripes 2s linear infinite;
        transition: width 0.2s;
    }
}
</style>