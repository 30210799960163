<template>
  <div class="o-addresses-declaration">
    <h2 class="o-addresses-declaration__title title">Vérifiez les données suivantes</h2>
    <br/>
    <div v-if="companiesAddresses.length > 0">
      <caci-company 
        v-for="(address, index) in companiesAddresses" 
        :address="address" 
        :key="index" 
        @delete="handleDelete">
      </caci-company>
    </div>
    <p v-else>Aucune société rattachée</p>
    <div class="bottom-right-inline">
      <button
        class="button"
        :class="{ 'button--disabled': !canAddCompany }"
        @click.prevent="addCompany"
      >
        Ajouter une autre agence
      </button>
      <button
        class="button"
        @click.prevent="confirm"
      >
        Procéder au paiement
      </button>
    </div>
  </div>
</template>
<script>
import contactRepository from '../../repositories/contactRepository';
export default {
  data() {
    return {
      addresses: false,
    };
  },
  methods: {
    addCompany() {
      this.user.contact.addresses.push({
        id: false,
        company: "",
        email: "",
        phone: "",
        isMain: "",
        isActive: true,
      });
    },
    handleDelete(id){
      contactRepository.deleteAddress(id).then(()=>{
        const addressIndex = this.user.contact.addresses.findIndex((address)=>address.id==id)
        this.user.contact.addresses.splice(addressIndex, 1)
      })
    },
    confirm(){
      this.$emit('close')
    }
  },
  computed: {
    canAddCompany() {
      const addresses = this.user.contact.addresses;
      const lastAddress = addresses[addresses.length - 1];
      return lastAddress && lastAddress.company && lastAddress.id;
    },
    user() {
      return this.$user()
    },
    companiesAddresses(){
				return this.user.contact.addresses.filter(address => {
					return !address.isHome && address.isActive
				})
			},
  },
};
</script>
<style>
.o-popin__content:has(.o-addresses-declaration){
  width: 70%;
}
.o-addresses-declaration{
  .a-caci-company{
    text-align: left;
  }
}
</style>