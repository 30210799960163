<template>

	<main class="v-page" v-if="json && page && hasPermission &&!loading" :class="page.class">
		<component :is="item.component" :interface="item.data" v-for="(item, index) in page.components" :key="index" v-if="checkRole(user, item)"/>
	</main>
	<loader v-else-if="loading" class="v-page__loader"></loader>
	<not-found v-else></not-found>

</template>

<style lang="scss">

	@import '../environment';

	.v-page{
		background: $c-background; margin-bottom: $space-ll;
    .page--home &{ margin-bottom: $space }
		@media #{$to-tablet}{ margin-bottom: 0; padding-bottom: $space-m }

		&__loader{ position: relative; margin: $space-l 0 }

		&+*, *+&{
			position: absolute; left: 0; top: 0; width: 100%;
		}

    &.no-border, .page--foad &{ margin-bottom: 0 }
	}
</style>

<script>
	import Interface from '@/interface.yml';
  import documentRepository from "@/repositories/documentRepository";
	import Loader from "@/components/atom/loader";
	import Vue from 'vue';

	export default {
		components: {Loader},
		data() {
			return {
				json: Interface.pages,
				page: false
			}
		},
		computed:{
			root(){ return Vue.http.options.root },
			user(){ return this.$user() },
			changesSaved(){ return this.$store.getters.changesSaved() },
			permissions(){ return this.$store.getters.permissions() },
			hasPermission(){  
				const defaultAccess = this.$route.name != "quotations";
				return this.checkPermission(this.page.permission, this.user, defaultAccess) 
			},
			loading(){ return !this.user }
		},
		mounted(){

			if(typeof this.$route.name !== 'undefined')
				this.page = this.json[this.$route.name];
			else if(this.$route.params.subpage)
				this.page = this.json[this.$route.params.subpage];
			else
				this.page = this.json[this.$route.params.page];
		},
		beforeRouteLeave (to, from, next) {
			if(!this.changesSaved){
				const answer = window.confirm("Vous n'avez pas enregistré les modifications, souhaitez-vous vraiment quitter cette page ?");
				if (answer) {
					this.$store.commit('changesSaved', true);
					next()
				} else {
					next(false)
				}
			} else {
				next()
			}
		},
		beforeRouteUpdate  (to, from, next) {
			if(!this.changesSaved){
				const answer = window.confirm("Vous n'avez pas enregistré les modifications, souhaitez-vous vraiment quitter cette page ?");
				if (answer) {
					this.$store.commit('changesSaved', true);
					next()
				} else {
					next(false)
				}
			} else {
				next()
			}

		}
	}
</script>
