<template>
	<div class="o-request">

		<h2 class="title">{{interface.title}}</h2>

		<div v-if="sent" class="o-request__confirmation-notice">			
			<h1>Merci</h1>
			<p>Votre message nous a été transmis, nous reviendrons vers vous dans les meilleurs délais</p>
		</div>

		<form v-else @submit.prevent="sendRequest" enctype="multipart/form-data" class="equal-padding-l" ref="form" id="request">

			<vue-form-generator tag="div" :schema="form" :model="params" :options="formOptions" class="m-contact-add__form" @model-updated="changesUpdated"></vue-form-generator>

			<div class="o-request__message">
				<b>Vérifiez les informations saisies, puis cliquez sur "Envoyer".</b><br/>
				Conformément à la loi informatique et liberté du 06.01.78, vous disposez d'un droit d'accès,
				de modification et d'annulation des informations communiquées au SNPI par courrier. <br>
				En tant qu’adhérent du SNPI, vos données à caractère personnel sont traitées par le SNPI, responsable de traitement, en vertu de la
				<a href="https://www.snpi.fr/politique-de-confidentialite-web" target="_blank" rel="noopener">Politique de confidentialité</a>
			</div>

			<div class="bottom-right">
				<submit class="button--secondary" text="Envoyer" :loading="loading" :success="sent" text-success="Envoyé" :error="error" :text-error="error"></submit>
			</div>
		</form>
	</div>
</template>

<style lang="scss">

	@import '../../environment';
	.o-request{
		&__message{ margin-top: $space; font-style: italic }

		.button--secondary + &__sent{ margin-top: $space-m }

		&__sent{
			font-size: $font-m; font-weight: bold; display: flex; justify-content: center; line-height: normal;

			& + *{ margin-top: $space-m }
			&:before{ background: $c-green; color: white; padding:2px; border-radius: $border-radius; margin-right: $space-xs }
		}
		&__confirmation-notice{
			h1{
				font-weight: bold;
				margin-bottom: $space-s;
			}
		}
	}
</style>

<script>

	import requestRepository from "@/repositories/requestRepository";

	export default {
		name: 'request',
		props:['data','interface'],
		data() {

			let self = this;

			return {
				loading: false,
				sent: false,
				error: false,
				params:{
					civility:'',
					firstname:'',
					lastname:'',
					email:'',
					recipient:'',
					subject:'',
					message:'',
					type:this.interface.type
				},
				formOptions: {
					validateAfterLoad: true,
					validateAfterChanged: true,
					validateAsync: true
				},
				form:{
					fields:[
						{
							type: "radios",
							inputName: "civility",
							model: "civility",
							styleClasses:["fields-label","radio-list-inputs","radio-list-inputs--inline"],
							label: "Civilité",
							values: [
								"Monsieur",
								"Madame"
							]
						},
						{
							type: "input",
							inputType: "text",
							model: "firstname",
							maxlength: 60,
							styleClasses: "fields-label fields-half",
							label: "Prénom",
							required: true
						},
						{
							type: "input",
							inputType: "text",
							model: "lastname",
							maxlength: 60,
							styleClasses: "fields-label fields-half",
							label: "Nom",
							required: true
						},
						{
							type: "input",
							inputType: "email",
							maxlength: 60,
							model: "email",
							styleClasses: "fields-label",
							label: "Email",
							required: true
						},
						{
							type: "select",
							model: "recipient",
							styleClasses: "fields-label fields-half",
							label: "Destinataire",
							visible(){ return self.interface.type === 'contact' },
							selectOptions:{
								noneSelectedText: "---"
							},
							values: [
								{id:'juridique_immo', name: 'Service juridique - Immobilier'},
								{id:'juridique_social', name: 'Service juridique - Social'},
								{id:'administratif_snpi', name: 'Service administratif - SNPI'},
								{id:'administratif_vhs', name: 'Service administratif - VHS'},
								{id:'administratif_asseris', name: 'Service administratif - ASSERIS'},
								{id:'formation', name: 'Service formations'},
								{id:'sinistres_vhs', name: 'Service sinistres - VHS'},
								{id:'sinistres_asseris', name: 'Service sinistres - ASSERIS'},
								{id:'communication', name:' Service communication'},
								{id:'syndicale', name: 'Service syndical'},
								{id:'technique', name: 'Service technique'}
							]
						},
						{
							type: "input",
							inputType: "text",
							model: "subject",
							styleClasses: "fields-label",
							label: "Sujet de la demande",
							visible(){ return self.interface.type === 'contact' }
						},
						{
							type: "textArea",
							model: "message",
							styleClasses: "fields-label",
							label: "Message",
							required: true,
							rows: 10,
							min: 10
						}
					]
				},
				allowEvent: false
			}
		},
		methods: {
			changesUpdated(){
				if(this.allowEvent)
					this.$store.commit('changesSaved', false);
			},
			sendRequest(){

				let self = this;

				this.loading = true;

				requestRepository.send(this.params).then(response=>{

					this.loading = false
					this.sent = true
					this.$store.commit('changesSaved', true);

					setTimeout(function () {
						self.sent = false;
						self.params.recipient = '';
						self.params.subject = '';
						self.params.message = '';
					}, 5000);

				}, response=>{

					this.loading = false;
					this.error = response.body.status_text;
					setTimeout(function () {
						self.error = false;
					}, 5000);
				})
			}
		},
		computed:{
			user(){ return this.$user() }
		},
		mounted(){
			this.params.civility = this.user.contact.civility;
			this.params.firstname = this.user.contact.firstname;
			this.params.lastname = this.user.contact.lastname;
			this.params.email = this.user.contact.email;
			setTimeout(()=>{this.allowEvent = true},1000)
		}
	}
</script>
