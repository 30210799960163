<template>
  <tr class="m-quotation-item" :class="{'m-quotation-item--disabled': isDisabled, 'm-quotation-item--expired': data.status != 'pending' }">
    <td data-label="Formation">
      <div class="m-quotation-item__formation">
          <training-format-illustration :data="data.formation_course" />
        <div class="m-training-item__infos">
          <h3 class="m-quotation-item__title">
            <b>{{ data.formation_course.formation.title }}</b>
          </h3>
          <div class="m-training-item__content">
            <training-item-details :data="data.formation_course" />
          </div>
        </div>
      </div>
    </td>
    <td data-label="Status">
      <span>{{ status }}</span>
    </td>
    <td data-label="Prix">
      <span>{{ formatPrice(data.created_price) }}</span>
    </td>
    <td data-label="Crée le">
      <span>{{ formatDate(data.created_at) }}</span>
    </td>
    <td data-label="Expire le">
      <span>{{ formatDate(data.expire_at) }}</span>
    </td>
    <td data-label="Collaborateurs">
      <span>{{ collaborator }}</span>
    </td>
    <td>
      <div class="m-quotation-item__ctas">
        <a class="button button--white" :disabled="!canDownload || !this.data.link" :class="{'button--disabled': !canDownload || !this.data.link}" :href="data.link && data.link.url">Télécharger</a>
        <button v-if="canSeeFormation" class="button" @click="goToFormationPage">Voir la formation</button>
        <button v-if="canBuy" @click="handleBuy" :disabled="orderLoading" class="button" :class="{'button--disabled': orderLoading}">
          Acheter 
          <loader v-if="orderLoading" />
          </button>
      </div>
      <p class="m-quotation-item__no-link-notice" v-if="canDownload && !this.data.link">Nous générons votre fichier...</p>
    </td>
  </tr>
</template>
<script>
import quotationRepository from '../../repositories/quotationRepository';

export default {
  props: ["data"],
  data() {
    return {
      orderLoading: false
    }
  },
  computed: {
    status() {
      return {
        pending: "En cours",
        expired: "Expiré",
        paid: "Payé",
      }[this.data.status];
    },
    collaborator() {
      const contact = [...this.$user().staff, this.$user().contact].find(
        ({ id }) => id === this.data.contact
      );
      return `${contact.firstname} ${contact.lastname}`;
    },
    canBuy(){
      return this.data.status === "pending" && (this.checkPermission('ROLE_FORMATION_SUBSCRIBE_WRITE', this.$user(), false) || this.$user().isCommercialAgent)
    },
    canDownload(){
      return ["paid", "pending"].includes(this.data.status)
    },
    canSeeFormation(){
      return !["pending", "paid"].includes(this.data.status)
    },
    isDisabled(){
      return !["pending", "paid"].includes(this.data.status)
    },
  },
  methods: {
    formatDate(isoDate) {
      return new Date(isoDate).toLocaleDateString("fr-FR");
    },
    handleBuy(){
      this.orderLoading = true;
      quotationRepository.createOrderFromQuotation(this.data.id).then((response)=>{
        this.$router.push({ name: "order", params: { id: response.order_id } });
      })
    },
    goToFormationPage(){
      this.$router.push({name: 'formation', params: {id: this.data.formation_course.id}})
    }
  },
};
</script>
<style lang="scss">
@import "../../environment";
.m-quotation-item {
    border-top: 2px solid $c-background;
    &:not(.m-quotation-item--disabled){
      color: $c-primary;
    }
  &__ctas{
    display: flex;
    @media #{$to-small} {
      flex-direction: column;
      a, button{
        width: 100%;
        margin-left: 0 !important;
        text-align: center;
        &:not(:first-child){
          margin-top: $space-s;
        }
      }
    }
  }

  &__formation {
    $c: &;
    display: flex;

    @media #{$include-phone} {
      place-items: center;
    }

    @media #{$from-small} {
      & > * + * {
        margin-left: $space-m;
      }
    }

    @media #{$to-tablet} {
      & > * + * {
        margin-left: $space-s;
      }
      display: flex; flex-direction: column; align-items: flex-start; gap: $space-s;
    }
  }

  td {
    vertical-align: middle;

    @media #{$from-small} {
      &:not(:first-child){
        width: 4%;
      }
      &:last-child{
        width: 12%;
      }
    }

    span{
        font-weight: bold;
    }

    &:first-child{
      @media #{$from-small}{
        width: 24%;
      }
      & > div {
        margin-top: $space-m;
      }
    }

    &:last-child{
      padding-left: $space-s;
    }
  }

  &__no-link-notice{
    margin-block: 1rem;
    font-weight: bold;
  }

  @media #{$to-tablet} {
  .m-training-item{
    &__col{
      flex-direction: row;
      position: relative;
      left: -90px;
      &--2{
        padding: 0;
        margin-left: 0.5rem;
      }
      img{
        height: 34px;
      }
      }
    &__infos{
      margin-left: 0;
    }
    &__details{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

@media #{$only-tablet} {
    .m-training-item{
      &__col{
        img{
          height: 54px !important;
        }
      }
    }
  }
&--expired{
  td{
    &:last-child{
      width: 15%;

      @media #{$to-tablet} {
        width: 100%;
      }
    }
  }
}
}
</style>
