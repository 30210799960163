import Vue from "vue";

export default {
  getUser(token) {
    return new Promise((resolve, reject) => {
        Vue.http.get(`renewal/${token}/infos`).then(response => {
            resolve(response);
        }).catch(reject);
    }) 
  },
  getMembership(token) {
    return new Promise((resolve, reject) => {
        Vue.http.get(`renewal/${token}/membership`).then(response => {
            resolve(response);
        }).catch(reject);
    }) 
  },
  createOrder(token, payload) {
    return new Promise((resolve, reject) => {
      Vue.http.post(`renewal/${token}/order`, payload).then((response) => {
        resolve(response);
      }, reject);
    });
  },
  terminate(token){
    return new Promise((resolve, reject) => {
      Vue.http.get(`renewal/${token}/terminate`).then((response) => {
        resolve(response);
      }, reject);
    });
  }
};
