<template>
  <div>
    <VuePassword
        class="form-control"
        v-model="value"
        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$"
        :type="type"
        :disabled="disabled"
        :id="getFieldID(schema)"
        :name="getFieldName(schema)"
        :autocomplete="schema.autocomplete"
        :maxlength="schema.max"
        :placeholder="schema.placeholder"
        ref="input"
        :strength="strength"
        :strengthMessages="['Mot de passe très faible, 6 characteres minimum, majuscule, minuscule et chiffres', 'Mot de passe trop faible, 6 characteres minimum, majuscule, minuscule et chiffres', 'Mot de passe faible, 6 characteres minimum, majuscule, minuscule et chiffres', 'Mot de passe moyen, 6 characteres minimum, majuscule, minuscule et chiffres', 'Mot de passe fort']"
        :disableStrength="schema.disableStrength"
        @input="updateStrength"
    />
    <div v-if="schema.description" class="custom-password--description"> {{schema.description}}</div>
  </div>
</template>

<script>
import { abstractField } from "vue-form-generator";
import VuePassword from "vue-password";

export default {
  name: "field-password-vue-custom",
  components: {
    VuePassword
  },
  mixins: [ abstractField ],
  props: ["schema"],
  data (){
    return{
      type: "password",
      strength: 0
    }
  },
  methods: {
    updateStrength(password) {
      if (!password) {
        this.strength = 0;
        return this.strength;
      }

      const variations = {
        length: password.length >= 6,
        digits: /\d/.test(password),
        lower: /[a-z]/.test(password),
        upper: /[A-Z]/.test(password)
      };

      const variationCount = Object.keys(variations).reduce((acum, check) => {
        return variations[check] ? acum + 1 : acum;
      }, 0);

      this.strength = variationCount;

      return this.strength;
    }
  },

}
</script>

<style lang="scss">
@import '../../../environment';
.custom-password{
  position: relative;
  &--description {
   font-size: $font-xxs; margin-top: 0.5rem;
  }
}
.VuePassword--good {
  color: #faad14!important;
}
.VuePassword__Toggle-Icon{ width: 1.5rem!important }
</style>