<template>
	<div class="m-renew" :class="{'m-renew--caci':user.isCommercialAgent}">
		<div class="title-big">{{ user.type === 'commercial_agent' ? config.option.renew_caci : config.option.renew_company }}</div>
		<div class="m-renew__grid">
			<div class="m-renew__col">
				<img :src="snpiLogo" alt="SNPI" class="m-renew__snpi" />
				<div class="primary"  v-if="data.snpi.total_amount">
					Montant de la cotisation :<br/>
					<span class="secondary">{{formatPrice(data.snpi.total_amount)}}</span>
					<div v-if="data.snpi.details.length > 1">
						<small>dont:</small>
						<div v-for="(details, index) in data.snpi.details">
							<small>{{details.year}} : {{formatPrice(details.amount)}}</small>
						</div>
					</div>
				</div>
				<form @submit.prevent="handleClickOnPayCta('snpi')" id="renew_create-order-snpi">
					<submit class="button--secondary" :loading="loading==='snpi'" text="Régler ma cotisation"></submit>
				</form>
				<div class="m-renew__overlay" v-if="!data.snpi.total_amount">
					<div class="title-big">Vous avez réglé votre cotisation SNPI.</div>
				</div>
			</div>
			<div class="m-renew__col" v-if="data.vhs.total_amount">
				<img src="/img/logo_snpi_assurances.png" alt="SNPI Assurances" class="m-renew__assurances" />
				<div class="primary">
					Montant des primes :<br/>
					<span class="secondary">{{formatPrice(data.vhs.total_amount)}}</span>
					<div>
						<small>dont:</small>
						<div v-for="(details, index) in data.vhs.details">
							<small>{{details.category}} : {{formatPrice(details.amount)}}</small>
						</div>
					</div>
				</div>
				<form @submit.prevent="handleClickOnPayCta('vhs')" id="renew_create-order-vhs">
					<submit class="button--secondary" :loading="loading==='vhs'" text="Régler mes primes"></submit>
				</form>
				<div class="m-renew__overlay" v-if="data.snpi.total_amount">
					<div class="title-big warning" data-icon="warning"> Vous devez tout d'abord régler votre cotisation SNPI</div>
				</div>
			</div>
			<div class="m-renew__col" v-if="data.asseris.total_amount">
				<img src="/img/logo_snpi_assurances.png" alt="SNPI Assurances" class="m-renew__assurances" />
				<div class="primary">
					Montant des primes :<br/>
					<span class="secondary">{{formatPrice(data.asseris.total_amount)}}</span>
					<div>
						<small>dont:</small>
						<div v-for="(details, index) in data.asseris.details">
							<small>{{details.category}} : {{formatPrice(details.amount)}}</small>
						</div>
					</div>
				</div>
				<form @submit.prevent="handleClickOnPayCta('asseris')" id="renew_create-order-asseris">
					<submit class="button--secondary" :loading="loading==='asseris'" text="Régler mes primes"></submit>
				</form>
				<div class="m-renew__overlay" v-if="data.snpi.total_amount">
					<div class="title-big warning" data-icon="warning"> Vous devez tout d'abord régler votre cotisation SNPI</div>
				</div>
			</div>
			<div class="m-renew__col" v-if="data.caci.total_amount">
				<img src="/img/logo_snpi_assurances.png" alt="CACI" class="m-renew__asseris" />
				<div class="primary">
					Montant des primes :<br/>
					<span class="secondary">{{formatPrice(data.caci.total_amount)}}</span>
					<div>
						<small>dont:</small>
						<div v-for="(details, index) in data.caci.details">
							<small>{{details.category}} : {{formatPrice(details.amount)}}</small>
						</div>
					</div>
				</div>
				<form @submit.prevent="handleClickOnPayCta('caci')" id="renew_create-order-caci">
					<submit class="button--secondary" :loading="loading==='caci'" text="Régler mes primes"></submit>
				</form>
				<div class="m-renew__overlay" v-if="data.snpi.total_amount">
					<div class="title-big warning" data-icon="warning"> Vous devez tout d'abord régler votre cotisation SNPI</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import orderRepository from "@/repositories/orderRepository";

	export default {
		name: "renew",
		props:['data'],
		data(){
			return{
				loading:false,
				currentType: false
			}
		},
		computed:{
			user(){ return this.$user() },
			config(){ return this.$config() },
			isCaci(){ return this.user.isCommercialAgent },
			snpiLogo(){ return this.user.isCommercialAgent ? "/img/logo-caci.svg" :"/img/logo-snpi-baseline.svg" },
		},
		methods:{
			handleClickOnPayCta(type){
				this.currentType = type
				if(this.isCaci){
					this.openAddressesDeclarationPopin()
				}
				else{
					this.createOrder(this.currentType)
				}
			},
			openAddressesDeclarationPopin(){
				this.$popin('addresses-declaration-popin', {}, true, 999999, ()=>this.createOrder(this.currentType))
			},
			createOrder(type){
				this.loading = type;
				orderRepository.create({type: 'membership_'+type}).then(id=>{
					this.$router.push({ name:'order', params:{ id: id }});
					this.loading = false;
				},response=>{
					this.loading = false;
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.m-renew{

		@media #{$from-small}{
			&__grid{ display: flex; justify-content: center }
		}
		&__col{
			text-align: center; padding: $space; position:relative; min-width: 30rem; display: flex; flex-direction: column; justify-content: space-between;

			@media #{$from-small}{ width: 50% }

			.button{ white-space: nowrap }
			img{ margin-left: auto; margin-right: auto }


			& > * + *{ margin-top: $space }
		}
		.warning{
			line-height: 1;
			&:before{ font-size: 3rem; display: block; margin-bottom: 1rem }
		}

		&__overlay{
			position: overlay(); background: rgba(#f5f7fa,.9); display: flex; align-items: center; justify-content: center; border-radius: $border-radius;
			margin-top: 0; padding: $space;
		}

		&__snpi, &__asseris{ width: 15rem }
		&__assurances{ width: 15rem }
		&--caci{
			.m-renew__snpi{
				width: 100%;
			}
		}
	}
</style>