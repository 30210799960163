<template>
    <div class="quotations container">
        <loader v-if="loading" static="1"></loader>
        <div class="block" v-else-if="quotations">
            <h1 class="title">Mes devis en cours</h1>
            <div class="quotations__list" v-if="pendingQuotations.length">
                <table>
                    <thead>
                        <tr>
                            <th>Formation</th>
                            <th>Statut</th>
                            <th>Prix</th>
                            <th>Crée le</th>
                            <th>Expire le</th>
                            <th>Collaborateur</th>
                        </tr>
                    </thead>
                    <quotation-item v-for="quotation in pendingQuotations" :data="quotation" />
                </table>
            </div>
            <div v-else>
                Pas de devis en cours
            </div>
            <h1 class="title">Mes anciens devis</h1>
            <div class="quotations__list" v-if="expiredQuotations.length">
                <table>
                    <thead>
                        <tr>
                            <th>Formation</th>
                            <th>Statut</th>
                            <th>Prix</th>
                            <th>Crée le</th>
                            <th>Expire le</th>
                            <th>Collaborateur</th>
                        </tr>
                    </thead>
                    <quotation-item v-for="quotation in expiredQuotations" :data="quotation" />
                </table>
            </div>
            <div v-else>
                Pas d'anciens devis
            </div>
        </div>
    </div>
</template>
<script>
import quotationRepository from "../../repositories/quotationRepository";
export default {
    name: "quotations",
    props: ["data"],
    data() {
        return {
            quotations: false,
            loading: true
        }
    },
    mounted() {
        quotationRepository.getAll().then((response) => {
            this.quotations = response
            this.loading = false
        })
    },
    computed: {
        pendingQuotations() {
            return this.quotations.filter(({ status }) => status === "pending").sort((quotationA, quotationB)=>new Date(quotationB.created_at) - new Date(quotationA.created_at))
        },
        expiredQuotations() {
            return this.quotations.filter(({ status, created_at }) => status !== "pending" && this.daysBetweenDates(new Date(created_at), new Date()) <= 365).sort((quotationA, quotationB)=>new Date(quotationB.created_at) - new Date(quotationA.created_at))
        }
    },
}
</script>
<style lang="scss">
@import "../../environment.scss";

.quotations {
    $c: &;

    &__list {
        padding: $space;

        table {
            width: 100%;

            th:first-child {
                padding-bottom: $space-m;
            }
        }

        @media #{$to-tablet} {

            table,
            thead,
            tbody,
            tr,
            th,
            td {
                display: block;
            }

            thead {
                display: none;
            }

            td {
                padding-left: 100px;
                position: relative;
                margin-top: $space-s;
                background: #FFF;

                &::before {
                    padding: $space-s;
                    content: attr(data-label);
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                }
                &:first-child{
                    &::before{
                        top: 73px;
                        height: fit-content;

                        @media #{$to-phone}  {
                            top: 56px;
                        }
                    }

                }
            }

            tr {
                margin-bottom: 1rem;
                &:first-of-type{
                    border-top: none;
                }
            }

            th+td {
                padding-left: 10px;
            }

        }
    }
}
</style>