<template>
  <component :is="linkComponent" :to="linkTo" :class="componentClass">
    <training-format-illustration :data="data"/>
		<div class="m-training-item__infos">
			<h3 class="m-training-item__title">
				<b>{{data.formation.title}}</b>
			</h3>
			<div class="m-training-item__content">
        <training-item-details :data="data" />
        <div class="m-training-item__participants" v-if="type === 'subscribed' && data.participants && data.participants.length > 0 && user.isLegalRepresentative">
          <div class="m-training-item__participants-label">Collaborateurs inscrits</div>
          <template v-if="showParticipants">
            <span v-for="(participant, index) in data.participants" :key="index" :class="{'is-present':participant.present}">
              {{ participant.contact.firstname }} {{ participant.contact.lastname }}<span v-if="index + 1 < data.participants.length">, </span>
            </span>
          </template>
          <template v-else>
            <span v-for="(participant, index) in data.participants.slice(0, 5)" :key="index" :class="{'is-present':participant.present}">
              {{ participant.contact.firstname }} {{ participant.contact.lastname }}<span v-if="index + 1 < data.participants.length && index < 4">, </span>
            </span>
            <span v-if="data.participants.length > 5"> et {{data.participants.length - 5}} autre{{data.participants.length - 5 > 1 ? 's' : ''}}.</span>
          </template>
        </div>
      </div>
      <div v-if="type === 'subscribed'" class="m-training-item__status">
        <span v-if="data.format === 'e-learning'">
          <b v-if="!isCurrentUserParticipant() && !data.completed">En cours</b>
          <b v-else-if="data.completed || isCompleted">Terminée</b>
          <span v-else-if="elearning_v2 && isCurrentUserParticipant()">
            <span v-if="data.completed || isCompleted">Terminée</span>
            <a v-else @click="redirectToElearning(data.id)" class="button button--small">
                <span v-if="participant.inProgress">Reprendre la formation</span>
                <span v-else>Commencer la formation</span>
            </a>
          </span>
          <span v-else-if="!elearning_v2 && isCurrentUserParticipant()">
            <a href="http://www.elearning-snpi.com/" target="_blank" rel="noopener noreferrer" class="button button--small" @click.stop="stopPropagation">Commencer la formation</a>
          </span>

          <b v-else-if="(isCompleted || data.status=='completed') && !isCurrentUserParticipant()">Terminée</b>
        </span>
        <span v-else-if="data.format === 'instructor-led'">
          <b v-if="data.status === 'potential'">À venir</b>
          </span>
          <span v-else-if="data.status === 'canceled'">
            <b>Annulé</b>
          </span>
          <span v-else>
            <b v-if="upcoming">A venir</b>
            <b v-else>Terminée</b>
          </span>
      </div>
    </div>
    <div class="m-training-item__training-col m-training-item__numbers primary" @click.prevent>
      <div class="m-training-item__dropdown dots" :class="{'dots--loading':downloading}" data-icon="dot-3" v-if="has_actions" @click.prevent>
        <loader v-if="downloading"></loader>
        <dropdown>
          <a class="dots__item" @click="showParticipants=true" v-if="data.participants.length > 5 && !showParticipants">
            Afficher tous les participants
          </a>
          <a class="dots__item" @click="openPopin">
            Récapitulatif d'achat
          </a>
          <a class="dots__item" @click="download('invoices')" v-if="documents.invoices.length">
            Télécharger les avoirs et factures
          </a>
          <a class="dots__item" @click="download('certificates')" v-if="documents.certificates.length">
            Télécharger les attestations
          </a>
          <a class="dots__item secondary" v-if="upcoming" @click="cancel">
            Annuler la formation
          </a>
        </dropdown>
      </div>
      <div class="m-training-item__distance">
        <div v-if="data.format === 'instructor-led' && data.distance">à
          <span v-if="data.distance > 1">{{data.distance}}km</span>
          <span v-else>{{data.distance * 1000}}m</span>
        </div>
      </div>
      <div class="m-training-item__price" v-if="type !== 'subscribed'">
        <span v-if="data.formation.price">{{formatPrice(data.formation.price)}} HT pp.</span>
        <span v-else>Gratuit</span>
      </div>
    </div>
  </component>
</template>

<script>
import formationCourseRepository from "@/repositories/formationCourseRepository";
import appendixRepository from "@/repositories/appendixRepository";
import cache from "@/services/cache";

export default {
  name: "training-item",
  props: ['data', 'type'],
  data() {
    return {
      documents: {
        loaded: false,
        invoices: [],
        certificates: []
      },
      otp: '',
      downloading: false,
      showParticipants: false,
      participant: false,
    }
  },
  methods: {
    download(item) {
      this.downloading = true;
      appendixRepository.download(this.documents[item]).then(
          response => {
            this.downloading = false;
          },
          response => {
            this.downloading = false;
          });
    },
    redirectToElearning(id) {
      formationCourseRepository.getELearningLink(id).then(response => {
        window.open(response.link, "_blank")
      });
    },
    openPopin() {
      this.$popin('training-receipt', this.data.id);
    },
    loadDocuments() {
      if (!this.documents.loaded) {
        this.downloading = true;
        formationCourseRepository.getDocuments(this.data.id).then(documents => {
          this.documents = documents;
          this.documents.loaded = true;
          this.downloading = false;
        }, response => {
          this.downloading = false;
        });
      }
    },
    cancel() {
      if (window.confirm('Voulez vous annuler la formation pour tous les participants ?')) {
        this.downloading = true;
        formationCourseRepository.cancel(this.data.id).then(response => {
          appendixRepository.sync().then(response => {
            window.location.reload();
          });
        })
            .catch(response => {
              this.downloading = false;
            });
      }
    },
    isCurrentUserParticipant() {
      return this.data.participants.some(participant => participant.contact.id === this.user.contact.id);
    }
  },
  computed: {
    linkComponent() {
      return this.type != 'subscribed' ? 'router-link' : 'div';
    },
    linkTo() {
      return this.type != 'subscribed' ? { name: 'formation', params: { id: this.data.id } } : null;
    },
    componentClass() {
      return 'm-training-item' + (this.type === 'subscribed' ? ' m-training-item--subscribed' : '') + ' m-training-item--' + this.data.format;
    },
    user() { return this.$user() },
    elearning_v2() { return this.user && this.user.contact && this.participant.elearning_version === 2 },
    current_time() { return this.$currentTime() },
    upcoming() { return this.current_time < this.data.startAt },
    has_actions() { return this.type === 'subscribed' && this.data.participants && this.hasRole(this.user, 'member') },
    isCompleted() { return this.isCurrentUserParticipant ? this.data.participants.find(participant => participant.contact.id === this.user.contact.id).completed : this.data.completed }
  },
  mounted() {
    if (this.data.participants) {
      let latestParticipant = null;

      this.data.participants.forEach(participant => {
        if (this.user.contact.id == participant.contact.id) {
          if (!latestParticipant || participant.createdAt > latestParticipant.createdAt) {
            latestParticipant = participant;
          }
        }
      });

      this.participant = latestParticipant;
    }
  }
}
</script>


<style lang="scss">
@use "sass:math";
@import '../../environment';
.m-training-item{
  $c: &;
  width: 100%; background: white; border-radius: $border-radius; padding: $space-m; position: relative; cursor: pointer; box-shadow: $subtle-box-shadow;
  transition: allow(box-shadow); display: block;
  &:hover{ box-shadow: $selected-box-shadow; z-index: 2 }

  @media #{$include-phone} {
    display: flex; gap: $space-s; place-items: center;
  }

  @media #{$from-small}{
    display: flex; padding: $space;
    & > * + *{ margin-left: $space-m }
  }

  @media #{$to-tablet}{
    & > * + *{ margin-top: $space-s }
  }

  &__col {
    display: inline-flex;
    flex-direction: row;
    border-radius: $border-radius;
    align-items: flex-end;
    color: $c-primary;
    @media #{$include-phone}{
      flex-direction: column;
    }

    &--2 {background-color: $c-background-lighter; padding: $space-s; border-top-right-radius: $border-radius; border-bottom-right-radius: $border-radius;
      text-align: center;place-content: center; font-size: 0.8rem; display: flex; flex-direction: column; align-items: center; width: 100%;
      @media #{$include-phone} {border-top-right-radius: 0; border-bottom-left-radius: $border-radius}
      img {margin-bottom: 4px;}
      b {max-width: 5rem}
    }
  }

  &__infos{
    flex: 1; text-align: left; color: $c-primary;
    b{ display: block }
  }

  &__status{
    margin-top: 1rem;
  }

  &__dropdown{
    position: absolute; right: 1rem; top: 1rem;
  }

  &__content{
    @media #{$from-small}{ display: flex; }
  }

  &__title{ color: $c-text }

  &__price{ font-size:$font-xl; font-weight: bold; color: $c-primary }

  &__training-col{ display: flex; justify-content: space-between; flex-direction: column }
  &__numbers{ text-align: right }
  &__type{ color: $c-primary }

  &__details{
    background: $c-background; border-radius: $border-radius; color: $c-primary;
    margin-top: $space-s; font-size: $font-xs; white-space: nowrap; display: flex; padding: 0 0.5rem;
    & > *{
      padding: math.div($space-s,2) $space-s;
    }

    .accessible-version &{ border: solid 1px $c-border }

    @media #{$to-tablet}{ display: inline-block }
  }

  &__distance{
    color: $c-main;
    @media #{$to-tablet}{ position: absolute; top: $space-m; right: $space-m }
  }

  &__participants{
    font-size: $font-xs;  margin-top: $space-s; position:relative;

    @media #{$from-small}{
      display: inline-block; margin-left: $space-s;
    }
    &-label{ color: $c-main }
    &-hover{
      background: white; padding: $space-s; position: absolute; box-shadow: $box-shadow; right: $space-m; left: 0; top: 100%; margin-top: $space-s
    }
  }

  &__date{
    position:relative; color: $c-secondary; font-weight: bold; text-align: center; background-color: #E6EAF1; line-height: 1;  padding: $space-s;
    border-bottom-left-radius: $border-radius; border-top-left-radius: $border-radius;
    &:before{ color: $c-primary; font-size: 4.5rem }
    .month{ text-transform: uppercase; font-size: 8px; display: block }
    & + *{ flex: 1 }
    @media #{$include-phone} {border-top-right-radius: $border-radius; border-bottom-left-radius: 0}
    @media #{$to-tablet}{
      &:not(.m-training-item__date--small) > *{
        display: inline-block;
        & + *{
          &:before{margin-right: math.div($space-s, 2) }
        }
      }
    }
  }

  &__date-short{
    position: overlay(); display: flex; flex-direction: column; align-items: center; justify-content: center;
    @media #{$to-phone-s} {font-size: $font-xxs; margin-top: 2.2rem}
    @media #{$from-phone-m} {margin-top: 3.2rem;}
    @media #{$from-small}{ margin-top: $space-s; font-size: $font-s; line-height: 0.9;}
  }

  &__receipt{ margin-top: $space-m}

  &__icon{ font-size: $font-xxl; color: $c-primary; padding: 0 8.5px }

  .right-space{ margin-right: math.div($space-s,2)}
  .month{font-size: inherit}
}

</style>
